import React from 'react';

import Icon from 'react-icons-kit';
import { chevronLeft } from 'react-icons-kit/feather/chevronLeft';
import { chevronRight } from 'react-icons-kit/feather/chevronRight';

import './Onboarding.css';

const Onboarding = () => (
  <div id={'Onboarding'}>
    <div className={'icons'}>
      <span>
        <Icon icon={chevronLeft} size={90} />
      </span>
      <span>
        <Icon icon={chevronRight} size={90} />
      </span>
    </div>
  </div>
);

export default Onboarding;
