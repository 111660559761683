import React, { useState, useCallback } from 'react';
import Cards, { Card } from 'react-swipe-card';
import Spinner from 'react-spinner-material';

import useNicocoUsers from './../hooks/useNicocoUsers';

import Match from './Match';
import Restart from './Restart';
import Onboarding from './Onboarding';

import './../Card.css';

const CardsProvider = () => {
  const [isFinish, setFinish] = useState(false);
  const [isMatch, setMatch] = useState(false);

  const [status, cards, generate, onboarded] = useNicocoUsers();

  const handleEnd = useCallback(() => {
    setFinish(true);
  }, []);

  const handleRestart = useCallback(() => {
    setFinish(false);
    generate();
  }, [generate]);

  const handleMatch = useCallback(() => {
    setMatch(true);
  }, []);

  const handleMatchClose = useCallback(() => {
    setMatch(false);
  }, []);

  const renderCards = useCallback((card, index) => {
    if (card.onboarding) {
      return (
        <Card
          key={index}
          onSwipeLeft={onboarded}
          onSwipeRight={onboarded}>
          <Onboarding />
        </Card>
      );
    }

    return (
      <Card
        key={index}
        onSwipeLeft={() => console.log('swipe left')}
        onSwipeRight={handleMatch}>
        <div
          className={'card-wrapper'}
          style={{ backgroundImage: `url(${card.picture})` }}>
          <div className={'overlay'} />
          <div className={'info-wrapper'}>
            <div className={'name'}>{`${card.name}, ${String(card.age)}`}</div>
            <div className={'bio'}>{card.description}</div>
          </div>
        </div>
      </Card>
    );
  }, [onboarded, handleMatch]);

  if (status === 'loading' || cards.length === 0)  {
    return (
      <div className={'spinner-wrapper'}>
        <Spinner
          size={40}
          spinnerColor={'#e374a9'}
          spinnerWidth={3}
          visible />
        <small>Patience, nous cherchons les utilisateurs qui vont te satisfaire à 100% ...</small>
      </div>
    );
  }

  if (isFinish && !isMatch) {
    return <Restart handleRestart={handleRestart} />;
  }

  return (
    <>
      {isMatch && <Match onClose={handleMatchClose} />}
      <Cards onEnd={handleEnd} className={'master-root'}>
        {cards.map(renderCards)}
      </Cards>
    </>
  );
};

export default CardsProvider;
