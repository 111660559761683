import React from 'react';

import Desktop from './components/Desktop';
import Login from './components/Login';
import CardsProvider from './components/CardsProvider';
import useAuth from './hooks/useAuth';

import './App.css';

const App = () => {
  const [isAuth, handleAuth] = useAuth();

  if (!window.isMobile()) {
    return <Desktop />;
  }

  if (!isAuth) {
    return <Login handleAuth={handleAuth} />;
  }

  return <CardsProvider />;
};

export default App;
