import { useState, useCallback } from 'react';

const LOGIN_KEY = 'nicoco:date:auth';

function useAuth() {
  const [auth, setAuth] = useState(false);

  const handleAuth = useCallback(() => {
    localStorage.setItem(LOGIN_KEY, true);
    setAuth(true);
  }, []);

  return [auth, handleAuth];
}

export default useAuth;