import React from 'react';
import Icon from 'react-icons-kit';

import {heart} from 'react-icons-kit/feather/heart'

import './Restart.css';

const Restart = ({ handleRestart }) => (
  <div id={'Restart'}>
    <h1>Nous pouvons te trouver encore plus de choix !</h1>
    <button className={'button'} onClick={handleRestart}>Recommencer <Icon size={24} icon={heart} /></button>
  </div>
);

export default Restart;