import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import Icon from 'react-icons-kit';
import { heart } from 'react-icons-kit/feather/heart';

import { MESSENGER_LINK } from './../constants/config';
import useGlobal from './../hooks/useGlobal';
import { build } from './../utils/share';

import './Match.css';

const MATCH_TEXT = [
  'Ton plus beau match !',
  'Tu ne peux rien y faire, c\'est le destin',
  'Tu pourras remercier Dieu pour ce cadeau',
  'Non tu ne rêves pas',
];

const Match = ({ onClose }) => {
  // eslint-disable-next-line no-unused-vars
  const [$, store] = useGlobal();

  // @XXX If we want to enable share option
  // const canShare = useMemo(() => !!navigator.share, []);
  const canShare = useMemo(() => false, []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleShare = useCallback(async () => {
    try {
      const card = await build(
        JSON.parse(store.currentMatch),
      );

      await navigator.share({
        title: 'app name', // @TODO
        text: `${card.name}, ${card.age}`,
        url: card.uri,
      });

    } finally {}
  }, [store]);

  const handleMessage = useCallback(() => {
    handleClose();
    window.open(MESSENGER_LINK);
  }, [handleClose]);

  return (
    <div className={'Match'}>
      <div className={'info'}>
        <span className={'icon-glossy'}>
          <Icon size={60} icon={heart} />
        </span>
        <h1>IT'S A MATCH</h1>
        <small>{_.shuffle(MATCH_TEXT)[0]}</small>
      </div>
      <div className={'button-container'}>
        <button className={'button'} onClick={handleMessage}>Envoyer un message</button>
        {canShare && <button className={'button'} onClick={handleShare}>Partage le !</button>}
        <button className={'button outline'} onClick={handleClose}>Plus tard ...</button>
      </div>
    </div>
  );
};

export default Match;
