import React from 'react';

import './Login.css';

const BACKGROUND = 'images/bg.jpg';
const logo = require('./../images/teem-logo.png');

const clearCache = () => window.location.reload(true);

const Login = ({ handleAuth }) => (
  <div id={'Login'} style={{ backgroundImage: `url(${BACKGROUND})` }}>
    <div className={'overlay'} />
    <img src={logo} alt={'teem'}  width={120} />
    <div className={'button-container'}>
      <button className={'button'} onClick={handleAuth}>Commencer</button>
      <p className={'tou-text'}>En appuyant sur "commencer" vous acceptez les <u>conditions générales d’utilistation</u>. Vos informations ne seront jamais transmisent à des tiers.</p>
    </div>
    <p className={'clear-cache'} onClick={clearCache}>récupérer le dernier contenu</p>
  </div>
);

export default Login;