import _ from 'lodash';

const MIN_AGE = 17;
const MAX_AGE = 36;
const CARDS_NUMBER = 23;
const BASE_PATH = `${process.env.PUBLIC_URL}/datas/`;

function preload(pictures) {
  pictures.forEach(picture => (new Image()).src = picture);
  return Promise.resolve();
}

async function from(file) {
  const now = String(Date.now());
  const response = await fetch(`${BASE_PATH}${file}?a=${now}`);
  const content = await response.json();
  return content;
}

async function load() {
  return await Promise.all([
    from('pictures.json'),
    from('names.json'),
    from('descriptions.json'),
  ]);
}

function generateCard(picture, name, description, age) {
  return {
    picture,
    name,
    description,
    age,
  };
}

async function init() {
  console.time('generate');
  const [
    rawPictures,
    names,
    descriptions,
  ] = await load();

  await preload(rawPictures);

  const pictures = _
    .shuffle(rawPictures)
    .slice(0, CARDS_NUMBER);

  let iteration = pictures.length;
  const used = { name: [], description: [] };
  const cards = [];

  while (iteration > 0) {
    const name = _.sample(names);
    const description = _.sample(descriptions);
    const age = _.random(MIN_AGE, MAX_AGE);

    if (
      used.name.includes(name) ||
      used.description.includes(description)
    ) {
      continue;
    }

    iteration--;
    used.name.push(name);
    used.description.push(description);

    cards.push(generateCard(
      pictures[iteration],
      name,
      description,
      age,
    ));
  }

  console.log('cards', cards.length);
  console.timeEnd('generate');
  return Promise.resolve(cards);
}

export { init };
