import { useCallback, useState } from 'react';

// eslint-disable-next-line no-unused-expressions
window.$state ? window.$state : {};

function useGlobal() {
  const [store, setStore] = useState(window.$state);

  const update = useCallback(($state) => {
    window.$state = {
      ...window.$state,
      ...$state,
    };

    setStore(window.$state);
  }, []);

  return [update, store];
}

export default useGlobal;

/**
 * @XXX DOM inspector method
 */

// const card = document.querySelector('.card.top-card');
// if (card && card.querySelector('.card-wrapper')) {
//   const currentMatch = card.querySelector('.card-wrapper').getAttribute('data-card');
//   if (currentMatch) {
//     update({
//       currentMatch,
//     });
//   }
// }
