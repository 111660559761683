import { useState, useEffect, useCallback, useMemo } from 'react';

import { init } from './../utils/cards';

const ONBOARDING_KEY = 'onboarded';

const isOnboarded = () => localStorage.getItem(ONBOARDING_KEY) || false;
const setOnboarded = () => localStorage.setItem(ONBOARDING_KEY, "letsgo");
const wait = (time = 1200) => new Promise(resolve => setTimeout(resolve, time));

function useNicocoUsers() {
  const [cards, setCards] = useState([]);
  const [status, setStatus] = useState('default');

  const shouldOnboarding = useMemo(() => !isOnboarded(), []);
  const onboarded = useCallback(() => setOnboarded(), []);

  const generate = useCallback(async () => {
    setStatus('loading');
    const cards = await init();
    await wait();
    setStatus('success');
    if (shouldOnboarding) {
      cards.unshift({ onboarding: true });
    }

    setCards(cards);
  }, [shouldOnboarding]);

  useEffect(() => {
    generate();
  }, [generate]);

  return [status, cards, generate, onboarded];
}

export default useNicocoUsers;
