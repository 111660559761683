import React from 'react';
import Icon from 'react-icons-kit';
import {smartphone} from 'react-icons-kit/feather/smartphone';

import './Desktop.css';

const Desktop = () => (
  <div class={'desktop-container'}>
    <span class={'icon-glossy'}>
      <Icon size={60} icon={smartphone} />
    </span>
    <h1>Oh non ! <br />Visite ce site sur téléphone pour une meilleure expérience !</h1>
  </div>
);

export default Desktop;
