
async function valid(share) {
  if (
    share.name &&
    share.age &&
    share.picture
  ) {
    return Promise.resolve();
  }

  throw Promise.reject();
}

export async function build(card) {
  try {
    await valid(card)
    const hash = btoa(
      JSON.stringify(card),
    );

    return {
      ...card,
      hash,
      uri: `${window.location.origin}/#share.${hash}`,
    };
  } catch (e) {
    return null;
  }
}

export async function parse() {
  try {
    if (window.location.hash) {
      const hash = window
        .location
        .hash
        .substring(1)
        .split('.')[1];
      const json = atob(hash);
      const user = JSON.parse(json);
      await valid(user);
      return {
        ...user,
        hash,
        uri: window.location.href,
      };
    }
  } finally {}
}
